<script setup>
import { computed, reactive, defineProps, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { localizeDate, jalaliToMiladi } from '@util/Time'
import days from '@composable/jalaliDays'

/*****************
 * INITILIZATION *
 *****************/
const props = defineProps(['destination'])
const store = useStore()
const router = useRouter()

// Initial api calls
store.dispatch('customer/fetchCustomerGroups')
store.dispatch('customer/fetchCustomerLabels')
store.dispatch('customer/fetchCustomerCities')
const groups = computed(() => store.state.customer.groups)
const labels = computed(() => store.state.customer.labels)
const cities = computed(() => store.state.customer.cities)

// Form items
const formItems = reactive({
  selectedDay: {
    1: { html: null, code: null },
    2: { html: null, code: null }
  },
  selectedAge: {
    1: null,
    2: null
  },
  selectedLastBuy: {
    1: null,
    2: null
  },
  selectedBuyCount: {
    1: null,
    2: null
  },
  selectedBuyPeriod: {
    1: { html: null, code: null },
    2: { html: null, code: null }
  },
  selectedGroup: null,
  selectedGender: null,
  selectedLabel: null,
  selectedCity: null
})

/*************************
 * MODALS AND THEIR DATA *
 *************************/
const modals = reactive({
  birthDay: false,
  age: false,
  notBuy: false,
  buyCount: false,
  buyPeriod: false,
  group: false,
  gender: false,
  label: false,
  city: false,
  final: false
})
const currentDate = ref(1)
const currentAge = ref(1)
const currentNotBuy = ref(1)
const currentBuyCount = ref(1)
const currentBuyPeriod = ref(1)

const ages = [...Array.from({ length: 91 }, (_, i) => i + 10)]
const notBuyDays = [...Array.from({ length: 365 - 1 + 1 }, (_, i) => i + 1)]
const buyCountNumbers = [...Array.from({ length: 201 }, (_, i) => i)]

/*****************
 * SET FUNCTIONS *
 *****************/
const selectBirthDay = (day) => {
  formItems.selectedDay[currentDate.value] = day
  modals['birthDay'] = false
}
const selectAge = (age) => {
  formItems.selectedAge[currentAge.value] = age
  modals['age'] = false
}
const selectNotBuy = (day) => {
  formItems.selectedLastBuy[currentNotBuy.value] = day
  modals['notBuy'] = false
}
const selectBuyCount = (n) => {
  formItems.selectedBuyCount[currentBuyCount.value] = n
  modals['buyCount'] = false
}
const selectBuyPeriod = (n) => {
  formItems.selectedBuyPeriod[currentBuyPeriod.value] = n
  modals['buyPeriod'] = false
}
const selectGroup = (group) => {
  formItems['selectedGroup'] = group
  modals['group'] = false
}
const selectLabel = (label) => {
  formItems['selectedLabel'] = label
  modals['label'] = false
}
const selectCity = (city) => {
  formItems['selectedCity'] = city
  modals['city'] = false
}

/***********************
 * SUBMITION FUNCTIONS *
 ***********************/
const btnLoading = computed(() => store.state.customer.btnLoading)
const customersCount = computed(
  () => store.state.customer.filteredCusotmerCount
)
const submitForm = () => {
  const {
    selectedAge: age,
    selectedGender: gender,
    selectedLastBuy: lastBuy,
    selectedBuyCount: buyCount,
    selectedDay: day,
    selectedGroup: group,
    selectedCity: city,
    selectedLabel: label,
    selectedBuyPeriod: buyPeriod
  } = formItems

  // init final form
  const finalForm = {}

  // age
  if (age['1'] && !age['2']) {
    finalForm['age'] = age['1']
  } else if (age['1'] && age['2']) {
    if (age['1'] > age['2']) {
      finalForm['age'] = age['2']
      finalForm['age_to'] = age['1']
    } else {
      finalForm['age'] = age['1']
      finalForm['age_to'] = age['2']
    }
  }

  // gender
  if (gender === 'مرد') finalForm['gender'] = '1'
  else if (gender === 'زن') finalForm['gender'] = '2'

  // last buy
  if (lastBuy['1'] && !lastBuy['2']) {
    finalForm['last_buy'] = lastBuy['1']
  } else if (lastBuy['1'] && lastBuy['2']) {
    if (lastBuy['1'] > lastBuy['2']) {
      finalForm['last_buy'] = lastBuy['2']
      finalForm['last_buy_to'] = lastBuy['1']
    } else {
      finalForm['last_buy'] = lastBuy['1']
      finalForm['last_buy_to'] = lastBuy['2']
    }
  }

  //buy count
  if (
    (buyCount['1'] || buyCount['1'] === 0) &&
    !(buyCount['2'] || buyCount['2'] === 0)
  ) {
    finalForm['buy_count'] = buyCount['1']
  } else if (
    (buyCount['1'] || buyCount['1'] === 0) &&
    (buyCount['2'] || buyCount['2'] === 0)
  ) {
    if (buyCount['1'] > buyCount['2']) {
      finalForm['buy_count'] = buyCount['2']
      finalForm['buy_count_to'] = buyCount['1']
    } else {
      finalForm['buy_count'] = buyCount['1']
      finalForm['buy_count_to'] = buyCount['2']
    }
  }

  // birthday format: 'mmdd'
  if (day['1']['code']) {
    // handling Today
    if (day['1']['code'] === '0000') {
      const code = localizeDate(new Date(), true)
      finalForm['bd'] = code
    } else {
      finalForm['bd'] = day['1']['code']
    }

    if (day['2']['code']) {
      if (day['2']['code'] === '0000') {
        const code = localizeDate(new Date(), true)
        finalForm['bd_to'] = code
      } else {
        finalForm['bd_to'] = day['2']['code']
      }
    }
  }

  // buy period format: 'mmdd'
  if (props.destination == 'lottery')
    if (buyPeriod['1']['code']) {
      // #TODO this doesn't consider end of the year to start of next year
      const convertedDate1 = jalaliToMiladi({
        year: 1400,
        month: buyPeriod['1']['code'].substring(0, 2),
        day: buyPeriod['1']['code'].substring(2)
      })
      finalForm['buy_from'] = convertedDate1

      if (buyPeriod['2']['code']) {
        const convertedDate2 = jalaliToMiladi({
          year: 1400,
          month: buyPeriod['2']['code'].substring(0, 2),
          day: buyPeriod['2']['code'].substring(2)
        })
        finalForm['buy_to'] = convertedDate2
      }
    }

  // group
  if (group) finalForm['group_id'] = group.id
  // label
  if (label) finalForm['label_id'] = label.id
  // city
  if (city) finalForm['city_id'] = city.id

  store.dispatch('customer/applyFilteredCustomers', {
    form: finalForm
  })
}

const confirmForm = () => {
  if (btnLoading.value) return
  if (customersCount.value === 0)
    return store.dispatch('addToast', {
      type: 'error',
      message: 'تعداد مشتری فیلترشده باید بزرگ‌تر از 0 باشد.'
    })
  modals['final'] = false
  switch (props.destination) {
    case 'notice':
      router.push({ name: 'NoticeNew' })
      break
    case 'offer':
      store.dispatch('offer/handleEnterOffer', {
        offerType: 'normal',
        customersCount: customersCount.value
      })
      break
    case 'survey':
      router.push({ name: 'SurveyNew' })
      break

    case 'lottery':
      store.dispatch('lottery/handleEnterLottery', {
        lotteryType: 'common',
        customersCount: customersCount.value,
        id: null,
        type: null
      })
      router.push({ name: 'LotteryNew' })
      break

    case 'bonus':
      store.dispatch('bonus/handleEnterBonus', {
        bonusType: 'normal',
        customersCount: customersCount.value
      })
      break

    default:
      break
  }
}
</script>

<template>
  <form novalidate class="form-wrapper" @submit.prevent="submitForm">
    <!-- SORT PART -->
    <section class="filter">
      <div class="filter__row row--small">
        <h2 class="filter__title">
          سطح مشتری
        </h2>
        <a
          class="filter__btn"
          @click="
            () => {
              modals['group'] = true
            }
          "
          v-html="
            formItems.selectedGroup ? formItems.selectedGroup['name'] : 'همه'
          "
        />
        <a
          class="filter__btn btn--period"
          @click="formItems['selectedGroup'] = null"
        >
          <svg viewBox="0 0 700 512" class="filter__period">
            <path
              d="M616.57 61l0 0c-7.03,-2.57 -14.88,1.07 -17.45,8.09l-32.33 88.21c-1.26,-3 -2.58,-6 -3.96,-8.98 -59.47,-128.27 -211.66,-184.04 -339.93,-124.57 -128.27,59.46 -184.04,211.66 -124.58,339.93 59.47,128.27 211.66,184.04 339.93,124.57 77.5,-35.93 129.05,-105.61 143.68,-183.61 3.79,-20.23 -21.22,-29.73 -27.47,-0.66 -14.59,67.86 -59.75,128.3 -127.58,159.75 -114.72,53.18 -250.84,3.3 -304.03,-111.42 -53.19,-114.73 -3.3,-250.85 111.42,-304.04 114.73,-53.18 250.85,-3.3 304.03,111.42 2.5,5.4 4.77,10.84 6.82,16.32l-5.13 -1.48 -94 -34.45c-7.03,-2.57 -14.88,1.07 -17.45,8.09l0 0c-2.58,7.03 1.06,14.88 8.09,17.45l124.83 45.75 0 0c7.02,2.57 14.87,-1.07 17.45,-8.09l0 0 45.75 -124.83c2.57,-7.03 -1.07,-14.88 -8.09,-17.45z"
            />
          </svg>
        </a>
      </div>
      <modal
        :is-open="modals['group']"
        @modalClosed="modals['group'] = false"
        title="سطح مشتری"
      >
        <ul class="items-wrapper" v-if="!!groups">
          <li v-for="group in groups" :key="group.id">
            <a
              :class="group === formItems['selectedGroup'] && 'active'"
              @click="() => selectGroup(group)"
              ><span class="black-font">{{ group['name'] }}</span>
            </a>
          </li>
        </ul>
      </modal>
    </section>
    <!-- GENDER PART -->
    <!-- <section class="filter">
      <div class="filter__row row--small">
        <h2 class="filter__title">
          جنسیت
        </h2>
        <a
          class="filter__btn"
          @click="modals['gender'] = true"
          v-html="formItems['selectedGender'] ?? 'همه'"
        />
        <a
          class="filter__btn btn--period"
          @click="formItems['selectedGender'] = null"
        >
          <svg viewBox="0 0 700 512" class="filter__period">
            <path
              d="M616.57 61l0 0c-7.03,-2.57 -14.88,1.07 -17.45,8.09l-32.33 88.21c-1.26,-3 -2.58,-6 -3.96,-8.98 -59.47,-128.27 -211.66,-184.04 -339.93,-124.57 -128.27,59.46 -184.04,211.66 -124.58,339.93 59.47,128.27 211.66,184.04 339.93,124.57 77.5,-35.93 129.05,-105.61 143.68,-183.61 3.79,-20.23 -21.22,-29.73 -27.47,-0.66 -14.59,67.86 -59.75,128.3 -127.58,159.75 -114.72,53.18 -250.84,3.3 -304.03,-111.42 -53.19,-114.73 -3.3,-250.85 111.42,-304.04 114.73,-53.18 250.85,-3.3 304.03,111.42 2.5,5.4 4.77,10.84 6.82,16.32l-5.13 -1.48 -94 -34.45c-7.03,-2.57 -14.88,1.07 -17.45,8.09l0 0c-2.58,7.03 1.06,14.88 8.09,17.45l124.83 45.75 0 0c7.02,2.57 14.87,-1.07 17.45,-8.09l0 0 45.75 -124.83c2.57,-7.03 -1.07,-14.88 -8.09,-17.45z"
            />
          </svg>
        </a>
      </div>
      <modal
        :is-open="modals['gender']"
        @modalClosed="modals['gender'] = false"
        title="جنسیت"
      >
        <ul class="items-wrapper">
          <li v-for="g in ['مرد', 'زن']" :key="g">
            <a
              :class="g === formItems['selectedGender'] && 'active'"
              @click="
                () => {
                  formItems['selectedGender'] = g
                  modals['gender'] = false
                }
              "
              ><span class="black-font">{{ g }}</span>
            </a>
          </li>
        </ul>
      </modal>
    </section> -->
    <!-- GROUP PART -->
    <section class="filter">
      <div class="filter__row row--small">
        <h2 class="filter__title">
          گروه‌ بندی
        </h2>
        <a
          class="filter__btn"
          @click="modals['label'] = true"
          v-html="
            formItems.selectedLabel ? formItems.selectedLabel['name'] : 'همه'
          "
        />
        <a
          class="filter__btn btn--period"
          @click="formItems['selectedLabel'] = null"
        >
          <svg viewBox="0 0 700 512" class="filter__period">
            <path
              d="M616.57 61l0 0c-7.03,-2.57 -14.88,1.07 -17.45,8.09l-32.33 88.21c-1.26,-3 -2.58,-6 -3.96,-8.98 -59.47,-128.27 -211.66,-184.04 -339.93,-124.57 -128.27,59.46 -184.04,211.66 -124.58,339.93 59.47,128.27 211.66,184.04 339.93,124.57 77.5,-35.93 129.05,-105.61 143.68,-183.61 3.79,-20.23 -21.22,-29.73 -27.47,-0.66 -14.59,67.86 -59.75,128.3 -127.58,159.75 -114.72,53.18 -250.84,3.3 -304.03,-111.42 -53.19,-114.73 -3.3,-250.85 111.42,-304.04 114.73,-53.18 250.85,-3.3 304.03,111.42 2.5,5.4 4.77,10.84 6.82,16.32l-5.13 -1.48 -94 -34.45c-7.03,-2.57 -14.88,1.07 -17.45,8.09l0 0c-2.58,7.03 1.06,14.88 8.09,17.45l124.83 45.75 0 0c7.02,2.57 14.87,-1.07 17.45,-8.09l0 0 45.75 -124.83c2.57,-7.03 -1.07,-14.88 -8.09,-17.45z"
            />
          </svg>
        </a>
      </div>
      <modal
        :is-open="modals['label']"
        @modalClosed="modals['label'] = false"
        title="گروه"
      >
        <ul class="items-wrapper" v-if="!!labels">
          <template v-for="label in labels" :key="label.id">
            <li v-if="!label['is_default']">
              <a
                :class="label === formItems['selectedLabel'] && 'active'"
                @click="() => selectLabel(label)"
                ><span class="black-font">{{ label['name'] }}</span>
              </a>
            </li>
          </template>
        </ul>
      </modal>
    </section>
    <!-- CITY PART -->
    <!-- <section class="filter">
      <div class="filter__row row--small">
        <h2 class="filter__title">
          شهر
        </h2>
        <a
          class="filter__btn"
          @click="modals['city'] = true"
          v-html="
            formItems.selectedCity ? formItems.selectedCity['name'] : 'همه'
          "
        />
        <a
          class="filter__btn btn--period"
          @click="formItems['selectedCity'] = null"
        >
          <svg viewBox="0 0 700 512" class="filter__period">
            <path
              d="M616.57 61l0 0c-7.03,-2.57 -14.88,1.07 -17.45,8.09l-32.33 88.21c-1.26,-3 -2.58,-6 -3.96,-8.98 -59.47,-128.27 -211.66,-184.04 -339.93,-124.57 -128.27,59.46 -184.04,211.66 -124.58,339.93 59.47,128.27 211.66,184.04 339.93,124.57 77.5,-35.93 129.05,-105.61 143.68,-183.61 3.79,-20.23 -21.22,-29.73 -27.47,-0.66 -14.59,67.86 -59.75,128.3 -127.58,159.75 -114.72,53.18 -250.84,3.3 -304.03,-111.42 -53.19,-114.73 -3.3,-250.85 111.42,-304.04 114.73,-53.18 250.85,-3.3 304.03,111.42 2.5,5.4 4.77,10.84 6.82,16.32l-5.13 -1.48 -94 -34.45c-7.03,-2.57 -14.88,1.07 -17.45,8.09l0 0c-2.58,7.03 1.06,14.88 8.09,17.45l124.83 45.75 0 0c7.02,2.57 14.87,-1.07 17.45,-8.09l0 0 45.75 -124.83c2.57,-7.03 -1.07,-14.88 -8.09,-17.45z"
            />
          </svg>
        </a>
      </div>
      <modal
        :is-open="modals['city']"
        @modalClosed="modals['city'] = false"
        title="شهر"
      >
        <ul class="items-wrapper" v-if="!!cities">
          <li v-for="city in cities" :key="city.id">
            <a
              :class="city === formItems['selectedCity'] && 'active'"
              @click="() => selectCity(city)"
              ><span class="black-font">{{ city['name'] }}</span>
            </a>
          </li>
        </ul>
      </modal>
    </section> -->
    <!-- AGE PART -->
    <!-- <section class="filter">
      <div class="filter__row">
        <h2 class="filter__title">
          سن
        </h2>
        <a
          class="filter__btn"
          @click="
            () => {
              modals['age'] = true
              currentAge = 1
            }
          "
        >
          {{ formItems.selectedAge['1'] ?? 'همه' }}
          <span v-show="formItems.selectedAge['1']">سال</span>
        </a>
        <span class="filter__text">تا</span>
        <a
          class="filter__btn"
          @click="
            () => {
              modals['age'] = true
              currentAge = 2
            }
          "
        >
          {{ formItems.selectedAge['2'] ?? 'همه' }}
          <span v-show="formItems.selectedAge['2']">سال</span>
        </a>
        <a
          class="filter__btn btn--period"
          @click="
            formItems['selectedAge'] = {
              1: null,
              2: null
            }
          "
        >
          <svg viewBox="0 0 700 512" class="filter__period">
            <path
              d="M616.57 61l0 0c-7.03,-2.57 -14.88,1.07 -17.45,8.09l-32.33 88.21c-1.26,-3 -2.58,-6 -3.96,-8.98 -59.47,-128.27 -211.66,-184.04 -339.93,-124.57 -128.27,59.46 -184.04,211.66 -124.58,339.93 59.47,128.27 211.66,184.04 339.93,124.57 77.5,-35.93 129.05,-105.61 143.68,-183.61 3.79,-20.23 -21.22,-29.73 -27.47,-0.66 -14.59,67.86 -59.75,128.3 -127.58,159.75 -114.72,53.18 -250.84,3.3 -304.03,-111.42 -53.19,-114.73 -3.3,-250.85 111.42,-304.04 114.73,-53.18 250.85,-3.3 304.03,111.42 2.5,5.4 4.77,10.84 6.82,16.32l-5.13 -1.48 -94 -34.45c-7.03,-2.57 -14.88,1.07 -17.45,8.09l0 0c-2.58,7.03 1.06,14.88 8.09,17.45l124.83 45.75 0 0c7.02,2.57 14.87,-1.07 17.45,-8.09l0 0 45.75 -124.83c2.57,-7.03 -1.07,-14.88 -8.09,-17.45z"
            />
          </svg>
        </a>
      </div>
      <modal
        :is-open="modals['age']"
        @modalClosed="modals['age'] = false"
        title="سن"
      >
        <ul class="items-wrapper">
          <li v-for="age in ages" :key="age">
            <a
              :class="age === formItems.selectedAge[currentAge] && 'active'"
              @click="() => selectAge(age)"
            >
              {{ age }} <span>سال</span>
            </a>
          </li>
        </ul>
      </modal>
    </section> -->
    <!-- BIRTH PART -->
    <!-- <section class="filter">
      <div class="filter__row">
        <h2 class="filter__title">
          روز تولد
        </h2>
        <a
          class="filter__btn"
          @click="
            () => {
              modals['birthDay'] = true
              currentDate = 1
            }
          "
          v-html="formItems.selectedDay['1']['html'] ?? 'همه'"
        >
        </a>
        <span class="filter__text">تا</span>
        <a
          class="filter__btn"
          @click="
            () => {
              modals['birthDay'] = true
              currentDate = 2
            }
          "
          v-html="formItems.selectedDay['2']['html'] ?? 'همه'"
        >
        </a>
        <a
          class="filter__btn btn--period"
          @click="
            formItems['selectedDay'] = {
              1: { html: null, code: null },
              2: { html: null, code: null }
            }
          "
        >
          <svg viewBox="0 0 700 512" class="filter__period">
            <path
              d="M616.57 61l0 0c-7.03,-2.57 -14.88,1.07 -17.45,8.09l-32.33 88.21c-1.26,-3 -2.58,-6 -3.96,-8.98 -59.47,-128.27 -211.66,-184.04 -339.93,-124.57 -128.27,59.46 -184.04,211.66 -124.58,339.93 59.47,128.27 211.66,184.04 339.93,124.57 77.5,-35.93 129.05,-105.61 143.68,-183.61 3.79,-20.23 -21.22,-29.73 -27.47,-0.66 -14.59,67.86 -59.75,128.3 -127.58,159.75 -114.72,53.18 -250.84,3.3 -304.03,-111.42 -53.19,-114.73 -3.3,-250.85 111.42,-304.04 114.73,-53.18 250.85,-3.3 304.03,111.42 2.5,5.4 4.77,10.84 6.82,16.32l-5.13 -1.48 -94 -34.45c-7.03,-2.57 -14.88,1.07 -17.45,8.09l0 0c-2.58,7.03 1.06,14.88 8.09,17.45l124.83 45.75 0 0c7.02,2.57 14.87,-1.07 17.45,-8.09l0 0 45.75 -124.83c2.57,-7.03 -1.07,-14.88 -8.09,-17.45z"
            />
          </svg>
        </a>
      </div>
      <modal
        :is-open="modals['birthDay']"
        @modalClosed="modals['birthDay'] = false"
        title="روز تولد"
      >
        <ul class="items-wrapper">
          <li v-for="day in days" :key="day">
            <a
              :class="day === formItems.selectedDay[currentDate] && 'active'"
              @click="() => selectBirthDay(day)"
              v-html="day['html']"
              height="60%"
            />
          </li>
        </ul>
      </modal>
    </section> -->
    <!-- BUY COUNT PART -->
    <section class="filter">
      <div class="filter__row">
        <h2 class="filter__title">
          تعداد خرید
        </h2>
        <a
          class="filter__btn"
          @click="
            () => {
              modals['buyCount'] = true
              currentBuyCount = 1
            }
          "
        >
          {{ formItems.selectedBuyCount['1'] ?? 'همه' }}
        </a>
        <span class="filter__text">تا</span>
        <a
          class="filter__btn"
          @click="
            () => {
              modals['buyCount'] = true
              currentBuyCount = 2
            }
          "
        >
          {{ formItems.selectedBuyCount['2'] ?? 'همه' }}
        </a>
        <a
          class="filter__btn btn--period"
          @click="
            formItems['selectedBuyCount'] = {
              1: null,
              2: null
            }
          "
        >
          <svg viewBox="0 0 700 512" class="filter__period">
            <path
              d="M616.57 61l0 0c-7.03,-2.57 -14.88,1.07 -17.45,8.09l-32.33 88.21c-1.26,-3 -2.58,-6 -3.96,-8.98 -59.47,-128.27 -211.66,-184.04 -339.93,-124.57 -128.27,59.46 -184.04,211.66 -124.58,339.93 59.47,128.27 211.66,184.04 339.93,124.57 77.5,-35.93 129.05,-105.61 143.68,-183.61 3.79,-20.23 -21.22,-29.73 -27.47,-0.66 -14.59,67.86 -59.75,128.3 -127.58,159.75 -114.72,53.18 -250.84,3.3 -304.03,-111.42 -53.19,-114.73 -3.3,-250.85 111.42,-304.04 114.73,-53.18 250.85,-3.3 304.03,111.42 2.5,5.4 4.77,10.84 6.82,16.32l-5.13 -1.48 -94 -34.45c-7.03,-2.57 -14.88,1.07 -17.45,8.09l0 0c-2.58,7.03 1.06,14.88 8.09,17.45l124.83 45.75 0 0c7.02,2.57 14.87,-1.07 17.45,-8.09l0 0 45.75 -124.83c2.57,-7.03 -1.07,-14.88 -8.09,-17.45z"
            />
          </svg>
        </a>
      </div>
      <modal
        :is-open="modals['buyCount']"
        @modalClosed="modals['buyCount'] = false"
        title="تعداد خرید"
      >
        <ul class="items-wrapper">
          <li v-for="n in buyCountNumbers" :key="n">
            <a
              :class="
                n === formItems.selectedBuyCount[currentBuyCount] && 'active'
              "
              @click="() => selectBuyCount(n)"
            >
              {{ n }}
            </a>
          </li>
        </ul>
      </modal>
    </section>
    <!-- LAST BUY PART -->
    <section class="filter" v-if="destination != 'lottery'">
      <div class="filter__row">
        <h2 class="filter__title">
          آخرین خرید
        </h2>
        <a
          class="filter__btn"
          @click="
            () => {
              modals['notBuy'] = true
              currentNotBuy = 1
            }
          "
        >
          {{ formItems.selectedLastBuy['1'] ?? 'همه' }}
          <span v-show="formItems.selectedLastBuy['1']">روز پیش</span>
        </a>
        <span class="filter__text">تا</span>
        <a
          class="filter__btn"
          @click="
            () => {
              modals['notBuy'] = true
              currentNotBuy = 2
            }
          "
        >
          {{ formItems.selectedLastBuy['2'] ?? 'همه' }}
          <span v-show="formItems.selectedLastBuy['2']">روز پیش</span>
        </a>
        <a
          class="filter__btn btn--period"
          @click="
            formItems['selectedLastBuy'] = {
              1: null,
              2: null
            }
          "
        >
          <svg viewBox="0 0 700 512" class="filter__period">
            <path
              d="M616.57 61l0 0c-7.03,-2.57 -14.88,1.07 -17.45,8.09l-32.33 88.21c-1.26,-3 -2.58,-6 -3.96,-8.98 -59.47,-128.27 -211.66,-184.04 -339.93,-124.57 -128.27,59.46 -184.04,211.66 -124.58,339.93 59.47,128.27 211.66,184.04 339.93,124.57 77.5,-35.93 129.05,-105.61 143.68,-183.61 3.79,-20.23 -21.22,-29.73 -27.47,-0.66 -14.59,67.86 -59.75,128.3 -127.58,159.75 -114.72,53.18 -250.84,3.3 -304.03,-111.42 -53.19,-114.73 -3.3,-250.85 111.42,-304.04 114.73,-53.18 250.85,-3.3 304.03,111.42 2.5,5.4 4.77,10.84 6.82,16.32l-5.13 -1.48 -94 -34.45c-7.03,-2.57 -14.88,1.07 -17.45,8.09l0 0c-2.58,7.03 1.06,14.88 8.09,17.45l124.83 45.75 0 0c7.02,2.57 14.87,-1.07 17.45,-8.09l0 0 45.75 -124.83c2.57,-7.03 -1.07,-14.88 -8.09,-17.45z"
            />
          </svg>
        </a>
      </div>
      <modal
        :is-open="modals['notBuy']"
        @modalClosed="modals['notBuy'] = false"
        title="آخرین خرید"
      >
        <ul class="items-wrapper">
          <li v-for="day in notBuyDays" :key="day">
            <a
              :class="
                day === formItems.selectedLastBuy[currentNotBuy] && 'active'
              "
              @click="() => selectNotBuy(day)"
            >
              {{ day }}
              <span>روز پیش</span>
            </a>
          </li>
        </ul>
      </modal>
    </section>
    <!-- LAST BUY PERIOD -->
    <section class="filter" v-if="destination == 'lottery'">
      <div class="filter__row">
        <h2 class="filter__title filter__title--gold">
          دوره خرید
        </h2>
        <a
          class="filter__btn"
          @click="
            () => {
              modals['buyPeriod'] = true
              currentBuyPeriod = 1
            }
          "
          v-html="formItems.selectedBuyPeriod['1']['html'] ?? 'همه'"
        >
        </a>
        <span class="filter__text">تا</span>
        <a
          class="filter__btn"
          @click="
            () => {
              modals['buyPeriod'] = true
              currentBuyPeriod = 2
            }
          "
          v-html="formItems.selectedBuyPeriod['2']['html'] ?? 'همه'"
        >
        </a>
        <a
          class="filter__btn btn--period"
          @click="
            formItems['selectedBuyPeriod'] = {
              1: { html: null, code: null },
              2: { html: null, code: null }
            }
          "
        >
          <svg viewBox="0 0 700 512" class="filter__period">
            <path
              d="M616.57 61l0 0c-7.03,-2.57 -14.88,1.07 -17.45,8.09l-32.33 88.21c-1.26,-3 -2.58,-6 -3.96,-8.98 -59.47,-128.27 -211.66,-184.04 -339.93,-124.57 -128.27,59.46 -184.04,211.66 -124.58,339.93 59.47,128.27 211.66,184.04 339.93,124.57 77.5,-35.93 129.05,-105.61 143.68,-183.61 3.79,-20.23 -21.22,-29.73 -27.47,-0.66 -14.59,67.86 -59.75,128.3 -127.58,159.75 -114.72,53.18 -250.84,3.3 -304.03,-111.42 -53.19,-114.73 -3.3,-250.85 111.42,-304.04 114.73,-53.18 250.85,-3.3 304.03,111.42 2.5,5.4 4.77,10.84 6.82,16.32l-5.13 -1.48 -94 -34.45c-7.03,-2.57 -14.88,1.07 -17.45,8.09l0 0c-2.58,7.03 1.06,14.88 8.09,17.45l124.83 45.75 0 0c7.02,2.57 14.87,-1.07 17.45,-8.09l0 0 45.75 -124.83c2.57,-7.03 -1.07,-14.88 -8.09,-17.45z"
            />
          </svg>
        </a>
      </div>
      <modal
        :is-open="modals['buyPeriod']"
        @modalClosed="modals['buyPeriod'] = false"
        title="دوره خرید"
      >
        <ul class="items-wrapper">
          <li v-for="day in days" :key="day">
            <a
              :class="
                day === formItems.selectedBuyPeriod[currentDate] && 'active'
              "
              @click="() => selectBuyPeriod(day)"
              v-html="day['html']"
              height="60%"
            />
          </li>
        </ul>
      </modal>
    </section>
    <!-- CONFIRMATION -->
    <section class="credit-buttons">
      <button
        @click="modals['final'] = true"
        type="submit"
        class="button button--black"
      >
        <span class="button__text">فیلتر</span>
      </button>
    </section>
    <modal
      stay-top
      :is-open="modals['final']"
      @modalClosed="modals['final'] = false"
    >
      <div class="confirm-modal-wrapper">
        <p class="filtered-title">مشتریان فیلتر شده</p>
        <div class="filtered-customers">
          <span
            v-if="!btnLoading"
            class="count"
            v-text="customersCount ? customersCount.toLocaleString() : 0"
          />
          <h3 v-if="!btnLoading" class="title">مشتری</h3>
          <img v-if="btnLoading" src="@img/vipon-loading.svg" class="loading" />
        </div>
        <div class="credit-buttons buttons--filter">
          <button
            class="button button--filter"
            @click="modals['final'] = false"
          >
            <svg class="button__icon" viewBox="0 0 512 512">
              <path
                d="M357.1 154.91l-0.01 -0.01c-5.23,-5.23 -13.8,-5.24 -19.04,0l-82.05 82.05 -82.05 -82.05c-5.24,-5.24 -13.81,-5.23 -19.04,0l-0.01 0.01c-5.23,5.23 -5.24,13.8 0,19.04l82.05 82.05 -82.05 82.05c-5.24,5.24 -5.23,13.81 0,19.04l0.01 0.01c5.23,5.23 13.8,5.24 19.04,0l82.05 -82.05 82.05 82.05c5.24,5.24 13.81,5.23 19.04,0l0.01 -0.01c5.23,-5.23 5.24,-13.8 0,-19.04l-82.05 -82.05 82.05 -82.05c5.24,-5.24 5.23,-13.81 0,-19.04z"
              />
            </svg>
          </button>
          <button
            class="button button--filter button--accept"
            @click="confirmForm"
          >
            <svg class="button__icon" viewBox="0 0 512 512">
              <path
                d="M394.58 154.91l0 -0.01c-5.24,-5.23 -13.8,-5.24 -19.04,0l-173.63 173.62 -65.45 -65.45c-5.24,-5.24 -13.8,-5.23 -19.04,0l0 0.01c-5.24,5.23 -5.24,13.8 0,19.04l74.97 74.97 0 0.01c5.24,5.23 13.81,5.24 19.05,0l183.14 -183.15c5.24,-5.24 5.24,-13.81 0,-19.04z"
              />
            </svg>
          </button>
        </div>
        <!-- <i class="gold-down" /> -->
      </div>
    </modal>
  </form>
</template>

<style scoped lang="scss">
/** Filter Block */
.filter {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  padding-left: 15px;
}
.filter__row {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 100px 10fr 10px 10fr 40px;
  justify-items: center;
  // column-gap: 10px;
}
.filter__hr {
  width: 100%;
  height: 1px;
  background-color: #333;
  margin: 20px 0;
}
.filter__title {
  width: 110px;
  font-size: 14px;
  text-align: right;
  background-color: #ddd;
  margin-left: -10px;
  // border-radius: 10px;
  height: 46px;
  line-height: 46px;
  padding-right: 20px;
}
.filter__title--gold {
  background-color: #c69d4e;
}
.filter__text {
  width: 10px;
  text-align: center;
}
.filter__btn {
  cursor: pointer;
  display: inline-block;
  background-color: #fff;
  box-shadow: 0 0 10px 1px rgba(#000, 0.2);
  color: #111;
  width: calc(100% - 10px);
  height: 46px;
  line-height: 46px;
  border-radius: 10px;
  text-align: center;
  outline: none;
}
.filter__btn > .smaller {
  margin-left: 5px;
}
.btn--period {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  box-shadow: none;
}
.row--small .filter__btn {
  grid-column: 2/5;
}
.row--small .btn--period {
  grid-column: 5/6;
}
.filter__period {
  width: 25px;
  height: 25px;
  fill: #777;
}
.period--active {
  background-color: #000;
}
.period--active .filter__period {
  fill: #c69d4e;
}
.def__text {
  margin: 0;
}
.def__input {
  margin: 5px;
}
.def__modal {
  // Design an span for modal btn
  cursor: pointer;
  display: inline-block;
  background-color: #111;
  color: #fff;
  padding: 2px 3px 0;
  min-width: 25px;
  border-radius: 5px;
  text-align: center;
}
.field {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  width: 100%;
}
.field__label {
  text-align: right;
  // width: 49%;
  margin: 0 10px;
}

.confirm-modal-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #eee;
}

.gold-down {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: 4px;
  background: #c69d4e;
}

// change in common css
.credit-buttons {
  margin: 10px 0 30px;
  padding: 0 15px;
}
.buttons--filter {
  justify-content: space-between;
  margin: 0px 0 18px;
}
.button {
  width: 100%;
}
.button--filter {
  width: 49%;
  background-color: #ddd;
}
.button--filter .button__icon {
  fill: #555;
}
.button--accept {
  background-color: #fff;
}

.button--accept .button__icon {
  fill: #c69d4e;
}

.filtered-title {
  padding: 40px 0 0;
}
.filtered-customers {
  margin: 10px 0 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 85px;
  overflow: hidden;
  position: relative;
  .title {
    margin: 0;
    font-size: 16px;
    color: #111;
  }
  .count {
    margin-bottom: 4px;
    position: relative;
    top: 1px;
    font-size: 52px;
    font-weight: bold;
    animation: blinking 1.3s infinite;
  }
  .loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 12px;
    bottom: 0;
    margin: auto;
    width: 120px;
    height: 120px;
  }
}
</style>
