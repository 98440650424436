<template>
  <div :class="['tbl', isDark && 'tbl--dark']">
    <div class="tbl__row">
      <h6 class="tbl__cell">
        سطح مشتری
      </h6>
      <i class="tbl__vr" />
      <h6 class="tbl__cell">
        {{ group }}
      </h6>
    </div>
    <i class="tbl__hr" v-if="!isDark" />
    <div class="tbl__row row--even">
      <h6 class="tbl__cell">
        جنسیت
      </h6>
      <i class="tbl__vr" />
      <h6 class="tbl__cell">
        {{ gender }}
      </h6>
    </div>
    <i class="tbl__hr" v-if="!isDark" />
    <div class="tbl__row ">
      <h6 class="tbl__cell">
        گروه
      </h6>
      <i class="tbl__vr" />
      <h6 class="tbl__cell">
        {{ label }}
      </h6>
    </div>
    <i class="tbl__hr" v-if="!isDark" />
    <div class="tbl__row row--even">
      <h6 class="tbl__cell">
        شهر
      </h6>
      <i class="tbl__vr" />
      <h6 class="tbl__cell">
        {{ city }}
      </h6>
    </div>
    <i class="tbl__hr" v-if="!isDark" />
    <div class="tbl__row">
      <h6 class="tbl__cell">
        سن
      </h6>
      <i class="tbl__vr" />
      <h6 class="tbl__cell" v-html="age" />
    </div>
    <i class="tbl__hr" v-if="!isDark" />
    <div class="tbl__row row--even">
      <h6 class="tbl__cell">
        روز تولد
      </h6>
      <i class="tbl__vr" />
      <h6 class="tbl__cell" v-html="birthDay" />
    </div>
    <i class="tbl__hr" v-if="!isDark" />
    <div class="tbl__row">
      <h6 class="tbl__cell">
        تعداد خرید
      </h6>
      <i class="tbl__vr" />
      <h6 class="tbl__cell" v-html="buyCount" />
    </div>
    <template v-if="type == 'all'">
      <i class="tbl__hr" v-if="!isDark" />
      <div class="tbl__row row--even">
        <h6 class="tbl__cell">
          آخرین خرید
        </h6>
        <i class="tbl__vr" />
        <h6 class="tbl__cell" v-html="lastBuy" />
      </div>
    </template>
    <template v-if="type == 'lottery'">
      <i class="tbl__hr" v-if="!isDark" />
      <div class="tbl__row row--even">
        <h6 class="tbl__cell">
          دوره خرید
        </h6>
        <i class="tbl__vr" />
        <h6 class="tbl__cell" v-html="buyPeriod" />
      </div>
      <i class="tbl__hr" v-if="!isDark" />
      <div class="tbl__row ">
        <h6 class="tbl__cell">
          هر شانس
        </h6>
        <i class="tbl__vr" />
        <h6 class="tbl__cell cell--rial" v-html="chance" />
      </div>
    </template>
  </div>
</template>

<script>
import days from '@composable/jalaliDays'
import dateConvertor from '@composable/dateConvertor'
export default {
  name: 'FiltersTable',
  props: {
    filters: Object,
    isDark: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'all'
    }
  },
  setup() {
    const { localizeDate } = dateConvertor()

    return { localizeDate }
  },
  computed: {
    gender() {
      const gender = this.filters?.gender
      if (gender == '1') return 'مرد'
      else if (gender == '2') return 'زن'
      else return 'همه'
    },
    age() {
      const age = this.filters?.age
      const ageTo = this.filters?.age_to
      if (age && ageTo)
        return `${age} سال <span style="color: ${
          !this.isDark ? '#c69d4e' : '#777'
        };">تا</span> ${ageTo} سال`
      else return age ? age + 'سال' : 'همه'
    },
    birthDay() {
      let bd = this.filters?.bd
      let bdTo = this.filters?.bd_to

      if (bd) {
        if (bd < 1000) bd = '0' + bd
        const bdText = days.find((day) => day.code === String(bd))
        if (bdTo) {
          if (bdTo < 1000) bdTo = '0' + bdTo
          const bdToText = days.find((day) => day.code === String(bdTo))
          return `${bdText.html} <span style="color: ${
            !this.isDark ? '#c69d4e' : '#777'
          };">تا</span> ${bdToText.html}`
        } else return bdText.html
      } else {
        return 'همه'
      }
    },
    buyCount() {
      const buy = this.filters?.buy_count
      const buyTo = this.filters?.buy_count_to
      if ((buy || buy === 0) && (buyTo || buyTo === 0))
        return `${buy} <span style="color: ${
          !this.isDark ? '#c69d4e' : '#777'
        };">تا</span> ${buyTo}`
      else return buy || buy === 0 ? buy : 'همه'
    },
    lastBuy() {
      const last = this.filters?.last_buy
      const lastTo = this.filters?.last_buy_to
      if (last && lastTo)
        return `${last} روز پیش <span style="color: ${
          !this.isDark ? '#c69d4e' : '#777'
        };">تا</span> ${lastTo} روز پیش`
      else return last ? last + 'روز پیش' : 'همه'
    },
    group() {
      const group = this.filters?.group__name
      return group ?? 'همه'
    },
    label() {
      const label = this.filters?.label__name
      return label ?? 'همه'
    },
    city() {
      const city = this.filters?.city__name
      return city ?? 'همه'
    },
    chance() {
      const chance = this.filters?.chance
      return chance ? chance.toLocaleString() : 0
    },
    buyPeriod() {
      let buyFrom = this.filters?.buy_from
      let buyTo = this.filters?.buy_to

      if (buyFrom) {
        buyFrom = this.localizeDate(buyFrom)
          .substring(5)
          .replace('/', '')
        const buyFromText = days.find((day) => day.code === String(buyFrom))
        if (buyTo) {
          buyTo = this.localizeDate(buyTo)
            .substring(5)
            .replace('/', '')
          const buyToText = days.find((day) => day.code === String(buyTo))
          return `${buyFromText.html} <span style="color: ${
            !this.isDark ? '#c69d4e' : '#777'
          };">تا</span> ${buyToText.html}`
        } else return buyFromText.html
      } else {
        return 'همه'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$table-border-color: #bbb;
$table-border-color-dark: #181818;
.tbl {
  width: 96%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid $table-border-color;
  border-radius: 15px;
  overflow: hidden;
}
.tbl__row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}
.row--even {
  background-color: #ddd;
}
.tbl__hr {
  height: 1px;
  width: 100%;
  background-color: $table-border-color;
  &:last-child {
    display: none;
  }
}
.tbl__vr {
  width: 1px;
  height: 100%;
  background-color: $table-border-color;
}
.tbl__cell {
  width: calc(50% - 1px);
  text-align: right;
  // height: 100%;
  padding-right: 10px;
  font-size: 14px;
}
.cell--rial::after {
  content: 'R';
  font-size: 10px;
  position: relative;
  top: -3px;
}
.tbl--dark {
  border-color: $table-border-color-dark;
}
.tbl--dark .tbl__hr {
  background-color: $table-border-color-dark;
}
.tbl--dark .tbl__vr {
  background-color: $table-border-color-dark;
}
.tbl--dark {
  border-color: $table-border-color-dark;
}
.tbl--dark .row--even {
  background-color: #111;
}
.tbl--dark .tbl__cell {
  color: #777;
}
</style>
