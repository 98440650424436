<template>
  <div class="empty">
    <p class="empty__desc desc--first">هیچ {{ descTop }} ایجاد نکرده‌ اید!</p>
    <i class="empty__hr" />
    <p class="empty__desc">
      قبل از ایجاد
      {{ descBottom }}<br />
      لطفا آموزش را با دقت ببینید.
    </p>
  </div>
</template>

<script>
export default {
  name: 'EmptyPart',
  props: {
    descTop: String,
    descBottom: String
  }
}
</script>

<style lang="scss" scoped>
.empty {
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 15px;
}
.empty__desc {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0 20px;
  text-align: center;
  line-height: 25px;
  color: #777;
  height: 100px;
}
.desc--first {
  height: 70px;
  color: #c69d4e;
}
.empty__hr {
  width: 90%;
  height: 1px;
  background-color: #ddd;
}
</style>
